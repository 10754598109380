<template>
  <div class="zj-info" v-html="zj_content">
    
  </div>
</template>

<script>
// import Vue from "vue";

export default {
  name: "zj",
  data() {
    return {
      qrcode: {},
      images: [],
      list: [],
      zj_content: null
    };
  },
  created() {
    document.title = "溯源信息";
  },
  mounted() {
    // console.log(this.$route.params.sku);
    // const sku =
    document.title = "溯源信息";

    this.loadData();
  },
  methods: {
    loadData() {
      const sku = this.$route.params.sku;
      this.$get("qrcode/prod/" + sku, null, (res) => {
        // console.log(res);
        if (res.code === 0) {
          this.qrcode = res.data || {};
          if (this.qrcode && this.qrcode.qrcode_info) {
            this.zj_content = this.qrcode.qrcode_info.zj_content;
          }
        }
      });
    },
  },
};
</script>

<style>
  .zj-info {
    padding: 15px;
    font-size: 14px!important;
    color: #333;
    
  }
  .zj-info p img {
    max-width: 100%!important;
}
</style>